import { Box, Flex } from '@chakra-ui/react'
import { navigate } from 'gatsby'

import { FC, useContext, useEffect } from 'react'

import Navbar from '~/components/Navbar'
import { withPage } from '~/components/page'
import { AuthContext } from '~/components/page/context'
import Settings from '~/components/settings/Settings'

const SettingsPage: FC = () => {
	const currentUser = useContext(AuthContext)

	useEffect(() => {
		if (currentUser.role?.name !== 'Admin') {
			navigate('/')
		}
	}, [currentUser])

	return (
		<Flex h='full' direction='column'>
			<Navbar />
			<Box flexGrow={1} bg='gray.100' px='4em'>
				<Settings />
			</Box>
		</Flex>
	)
}

export default withPage(SettingsPage, {
	public: false,
	name: 'Settings',
})
